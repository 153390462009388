<template>
  <md-dialog class="modal-share py-4" :md-active="isOpen">
    <span
      id="span-clout"
      class="material-icons close text-right"
      @click="closeModal"
      >close</span
    >
    <md-dialog-title>
      <img
        :src="course.poster.url"
        alt="Card image cap"
        class="w-100 details-img-share"
      />
    </md-dialog-title>
    <md-dialog-content>
      <h1 class="text-bold">Compartir</h1>

      <div class="buttons-social-media row">
        <div class="col pr-2">
          <a :href="sharedButton.urlFacebook" target="_blank">
            <button class="btn btn-facebook">
              <i class="fab fa-facebook-f"></i>
            </button>
          </a>
        </div>
        <div class="col px-0">
          <a :href="sharedButton.urlLinkedin" target="_blank">
            <button class="btn btn-linkedin">
              <i class="fab fa-linkedin-in"></i>
            </button>
          </a>
        </div>
        <div class="col pl-2">
          <a :href="sharedButton.urlTwitter" target="_blank">
            <button class="btn btn-twitter">
              <i class="fab fa-twitter"></i>
            </button>
          </a>
        </div>
      </div>
      <hr class="divider-share" />

      <div class="buttons-social-media2 row">
        <div class="col pr-0">
          <a :href="sharedButton.urlFacebookMessage" target="_blank">
            <button class="btn btn-messenger">
              <i class="fab fa-facebook-messenger"></i>
            </button>
          </a>
        </div>
        <div class="col">
          <a :href="sharedButton.urlEmailMessage" target="_blank">
            <button class="btn btn-gmail">
              <i class="fas fa-envelope"></i>
            </button>
          </a>
        </div>
      </div>

      <input
        :value="url"
        class="w-100 my-3 shadow-none border text-left input-share"
        disabled
        type="text"
      />
      <button class="copy-share" type="button" @click="onCopy">
        {{ textButton ? textButton : 'Copiar' }}
      </button>
    </md-dialog-content>
  </md-dialog>
</template>

<script>
export default {
  name: 'ShareClassVideoDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: () => {
        return {
          thumbnail_url: '',
          id: '',
          name: '',
          slug_name: '',
          instructors: [],
        };
      },
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      textButton: null,
    };
  },
  computed: {
    url() {
      return `https://maquillate.com${this.$route.path}`;
    },
    sharedButton() {
      const urlCopy = window.location;
      const courseUrl = this.url;
      const CourseName = `${this.course.title}`;
      const instructor = this.course.instructors[0] || {};
      const instructorName = `${instructor.firstname || ''} ${
        instructor.lastname || ''
      }`;
      const emailSubject = `Mira esto en Maquíllate.com - ${CourseName}`;
      const emailBody = `Dictado por: ${instructorName} %0D%0A ${urlCopy}`;
      const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
      return {
        urlFacebook: `https://www.facebook.com/sharer/sharer.php?u=${courseUrl}`,
        urlTwitter: `https://twitter.com/intent/tweet?url=${courseUrl}`,
        urlLinkedin: `https://www.linkedin.com/shareArticle/?url=${courseUrl}`,
        urlEmailMessage: `${mailtoLink}`,
        urlFacebookMessage: `https://www.messenger.com/new/?ref=shortcut&text=${courseUrl}`,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onCopy: function () {
      navigator.clipboard.writeText(this.url);
      this.textButton = 'Copiado';
    },
  },
};
</script>

<style>
.md-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
