<template>
  <div class="position-relative">
    <video
      id="hls-video"
      class="video-js vjs-custom-skin vjs-overlay"
      controls
      preload="metadata"
    ></video>
    <template
      v-if="
        !this.isAuthenticated &&
        this.getPublicCurrentClass &&
        this.getPublicCurrentClass.id
      "
    >
      <div class="position-absolute register-form">
        <div class="row">
          <div class="col-2 pr-0 pl-0">
            <img
              src="@/assets/images/padlock-white.png"
              alt=""
              class="image-class-unlock"
            />
          </div>
          <div class="col-10 unlock-course-mobile">
            <div class="font-weight-bold mb-2 text-unlock-class">
              DESBLOQUEA ESTA CLASE
            </div>
            <router-link to="/register">
              <base-button class="btn-maquillate p-2 text-unlock-button w-100">
                REGÍSTRATE GRATIS EN MAQUÍLLATE.COM
              </base-button>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
cd

<script>
import videojs from 'video.js';
import videojscontribqualitylevels from 'videojs-contrib-quality-levels';
import videojsqualityselector from 'videojs-hls-quality-selector';
import 'video.js/dist/video-js.css';
import { mapGetters } from 'vuex';
import { BaseButton } from '../../../../components';
import { FETCH_CURRENT_CLASS_USER } from '../../../../store/actions/classes';

require('@silvermine/videojs-airplay')(videojs);

export default {
  name: 'ClassVideo',
  components: {
    BaseButton,
  },
  emits: [
    'start-video',
    'stop-video',
    'end-video',
    'init-video',
    'current-time-video',
  ],
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        //playbackRates: [1, 1.5, 2],
        fluid: true,
        aspectRatio: '16:9',
        hls: true,
        plugins: {
          airPlay: {
            addButtonToControlBar: true, // defaults to `true`
          },
        },
      },
      loading: true,
      currentTime: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getPublicCurrentClass',
      'isAuthenticated',
      'getCurrentClassUser',
      'getProfile',
    ]),
  },
  watch: {
    getPublicCurrentClass: function (newValue) {
      if (this.isAuthenticated) {
        this.fetchCurrentClassUser();
      }
      videojs(`#hls-video`).poster(newValue.poster.url);
    },
    getCurrentClassUser: function (newValue) {
      if (!newValue) return;
      console.log('newValue', newValue);
      videojs(`#hls-video`).src({
        src:
          this.getPublicCurrentClass.video &&
          this.getPublicCurrentClass.video.url
            ? this.getPublicCurrentClass.video.url
            : '', // dynamic link
        type: 'application/x-mpegURL',
      });

      videojs(`#hls-video`).hlsQualitySelector = videojsqualityselector;
      videojs(`#hls-video`).hlsQualitySelector({
        displayCurrentQuality: false,
        vjsIconClass: 'vjs-icon-hd',
      });
      console.log('HERE');
      if (
        newValue &&
        newValue.currentTime &&
        parseInt(this.getPublicCurrentClass.video.duration) >
          newValue.currentTime
      ) {
        videojs(`#hls-video`).currentTime(newValue.currentTime);
      }
    },
  },
  mounted() {
    this.player = videojs(
      '#hls-video',
      this.videoOptions,
      function onPlayerReady() {
        this.hlsQualitySelector = videojsqualityselector;
        this.hlsQualitySelector({
          displayCurrentQuality: false,
          vjsIconClass: 'vjs-icon-hd',
        });

        this.addClass('vjs-maquillate');
        this.responsive(true);
      },
    );

    let vm = this;

    this.player.on('play', function () {
      vm.startVideo();
    });

    this.player.on('pause', function () {
      vm.stopVideo();
    });

    this.player.on('ended', function () {
      vm.endVideo(this.currentTime());
    });

    this.player.on('loadeddata', function () {
      vm.initialVideoTime();
    });
    this.player.on('timeupdate', function () {
      vm.currentTimeVideo(this.currentTime());
    });

    this.player.on('mouseout', function () {
      this.controls(false);
    });

    this.player.on('mouseover', function () {
      this.controls(true);
    });

    this.player.qualityLevels = videojscontribqualitylevels;
    this.player.qualityLevels();
  },

  methods: {
    fetchCurrentClassUser() {
      this.$store.dispatch(FETCH_CURRENT_CLASS_USER, {
        user_id: this.getProfile.id,
        class_id: this.getPublicCurrentClass.id,
      });
    },
    stopVideo() {
      this.$emit('stop-video', this.player);
    },
    initialVideoTime() {
      this.$emit('init-video', this.player);
    },
    startVideo() {
      this.$emit('start-video', this.player);
    },
    endVideo(time) {
      this.$emit('end-video', time);
    },
    currentTimeVideo(time) {
      this.$emit('current-time-video', time);
    },
  },
  // eslint-disable-next-line
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
.register-form {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-unlock-class {
  font-size: 23px;
  line-height: 20px !important;
  color: #fff;
}
.image-class-unlock {
  width: 62px;
  height: 62px;
  padding: 0px !important;
}

.vjs-poster {
  background-size: cover !important;
}

.text-unlock-button {
  font-size: 10px !important;
}

@media screen and (max-width: 1000px) {
  .text-unlock-class {
    font-size: 14px;
  }

  .image-class-unlock {
    width: 56px !important;
    height: 56px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
    max-width: 56px !important;
  }

  .text-unlock-button {
    font-size: 7px !important;
  }
}
</style>
