<template>
  <div class="card-h-w d-flex flex-column position-relative my-2">
    <div
      class="allClasses__container__classes d-flex flex-column position-relative"
    >
      <router-link :to="`/cursos/curso/${data.id}/${data.slug_name}`">
        <div class="text-header-img">
          <img
            :src="
              data && data.thumbnail_url
                ? data.thumbnail_url
                : require('@/assets/images/logo/placeholder.png')
            "
            alt="video"
            class="image-card-classes"
          />
        </div>
      </router-link>
      <div class="card-class-text d-flex flex-column justify-content-between">
        <h3
          class="text-white text-uppercase font-weight-bolder card-course-title mr-1"
        >
          {{ data.name }}
        </h3>
        <div class="mt-1 mb-0 card-course-instructor">
          <template v-if="data.instructors && data.instructors.length">
            <div class="text-white">
              <span
                v-for="(instructor, index) in getInstructors(data.instructors)"
                :key="instructor.id"
                class="text-white instructor-text"
              >
                <router-link
                  :to="`/instructores/instructor/${
                    instructor.id
                  }/${instructor.firstname.toLowerCase()}-${instructor.lastname.toLowerCase()}`"
                  class="text-uppercase font-weight-bolder white-text text-decoration-none"
                >
                  {{ index ? ',' : null }}
                  {{ `${instructor.firstname} ${instructor.lastname}` }}
                </router-link>
              </span>
            </div>
          </template>
          <template v-else>
            <span
              class="text-uppercase font-weight-bolder white-text text-decoration-none"
            >
              No hay instructor seleccionado
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'format-duration';

export default {
  name: 'CardCourse',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatTime(time) {
      return format(time * 1000);
    },
    getInstructors(instructors) {
      return instructors.map(({ instructor }) => instructor);
    },
  },
};
</script>

<style scoped>
.card-video-time-container {
  z-index: 1;
  background-color: #b7227e;
  border-radius: 5px;
  line-height: 0px !important;
  padding: 10px;
  right: 15px;
  top: 17px;
}
.card-video-time-container span {
  line-height: 5px !important;
}
.card-video-time {
  font-size: 12px;
  font-weight: 500;
  color: white;
}
.card-course-title {
  font-weight: 900 !important;
  font-size: 14px;
  text-align: start;
}
.card-course-instructor {
  text-align: start;
}
.white-text {
  font-size: 12px;
  color: #fff !important;
}
.card-class-text {
  height: 140px;
  padding: 20px 18px;
}
.image-card-classes {
  height: 160px;
  width: 100%;
  display: cover;
  border-radius: 10px 10px 0px 0px;
  border: none;
  object-fit: cover;
}
.tag-class-span {
  color: #fff;
}
.tag-card-class {
  z-index: 1;
  background-color: #b7227e;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
  left: 15px;
  top: 17px;
  height: 25px;
  align-content: center;
  line-height: 2px !important;
}
.card-video-tag {
  font-size: 12px;
  font-weight: 600;
  color: white;
}
.allClasses__container__classes {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .card-h-w {
    height: 300px;
    border-radius: 10px;
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .card-h-w {
    height: 300px;
    border-radius: 10px;
    width: 100%;
    padding-right: 10px;
  }
}
</style>
