<template>
  <div class="row mt-0">
    <div class="col-sm-12">
      <div
        class="d-flex justify-content-between align-items-center class-featured-md"
      >
        <h1 class="allClasses__subtitles mobile-space m-0 p-0">
          Clases destacadas
        </h1>
        <div>
          <router-link class="text-decoration-none" :to="$route.params.slug ? `/explorar/destacados/categoria/${$route.params.slug}`: '/explorar/destacados'">
            <button
              class="allClasses__container__classes__button rounded p-r-view-classes-mobile"
            >
              Ver Todos
            </button>
          </router-link>
        </div>
      </div>
      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-6 col-lg-4"
        />
      </div>
      <div v-else>
        <div
          v-if="
            getPublicFeaturedClasses.items &&
            getPublicFeaturedClasses.items.length
          "
          class="row show-in-desktop"
        >
          <card-class
            v-for="_class in getPublicFeaturedClasses.items"
            :key="_class.id"
            :data="_class"
            class="col-12 col-sm-6 col-md-6 col-lg-4"
          />
        </div>

        <div
          v-if="
            getPublicFeaturedClasses.items &&
            getPublicFeaturedClasses.items.length
          "
          class="col-12 view-home-tabs mx-0 px-0 view-tabs-mobile mb-4 show-in-mobile"
        >
          <carousel
            :navigation-enabled="true"
            :per-page="1"
            :per-page-custom="[
              [545, 2],
              [825, 3],
              [1200, 4],
            ]"
            :scroll-per-page="true"
            :speed="500"
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for="_class in getPublicFeaturedClasses.items"
              :key="_class.id"
              class="d-flex flex-column"
            >
              <card-class :data="_class" class="card-course-width mx-0" />
            </slide>
          </carousel>
        </div>
        <div v-else class="text-center pt-6 pb-6">
          <h3>No hay contenido disponible</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonCardVideo from '../../../Components/skeletons/SkeletonCardVideo.vue';
import CardClass from '../../Courses/components/CardClass.vue';
import { FETCH_PUBLIC_FEATURED_CLASSES } from '../../../../store/actions/public';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'FeaturedClasses',
  components: {
    SkeletonCardVideo,
    CardClass,
    Carousel,
    Slide,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getPublicFeaturedClasses', 'getPublicCategories']),
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.isLoading = true;
      await this.fetchClassFeatured({
        per_page: 3,
        order_by: 'DESC',
        category_slug: slug,
        featured: 1,
      });
    },
  },
  async mounted() {
    await this.fetchClassFeatured();
  },
  methods: {
    async fetchClassFeatured(
      filter = {
        per_page: 3,
        order_by: 'DESC',
        category_slug: this.$route.params.slug,
        featured: 1,
      },
    ) {
      await this.$store.dispatch(FETCH_PUBLIC_FEATURED_CLASSES, filter);
      this.isLoading = false;
    },
  },
};
</script>

<style>
.md-tabs-navigation .md-button-content {
  color: #fff;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:hover,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:active,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active:first-child {
  background-color: #b7227e;
}

.show-in-mobile {
  display: none;
}

@media (max-width: 576px) {
  .show-in-desktop {
    display: none;
  }
  .show-in-mobile {
    display: block;
    padding-top: 10px;
  }
  .p-r-view-classes-mobile {
    margin-right: 0px !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .class-featured-md {
    padding-right: 15px;
  }
}
</style>
