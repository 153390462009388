var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"highlight-body showClasses-rut"},[_c('div',{staticClass:"page-container md-layout-column"},[_c('div',[_c('div',{staticClass:"container-video-class h-100 show-courses__body show-classes_video"},[_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-lg-8 col-md-12 col-12 p-0"},[_c('class-video',{staticStyle:{"background-size":"cover"},on:{"current-time-video":_vm.currentTimeVideo,"end-video":_vm.endVideo}})],1),(_vm.isLoading)?_c('div',{staticClass:"col-lg-4 col-md-12 col-12 d-flex"},[_c('loading-panel',{staticStyle:{"width":"100%","background":"transparent !important","height":"100% !important"}})],1):_c('div',{staticClass:"show-videos-class-in-desktop col-lg-4 col-md-12 col-12 col-refactoring-boxs show-courses__info-instructor px-0 card-instructor-info"},[_c('div',{staticClass:"row show-courses__info-descrip-instructor mx-1"},[_c('div',{staticClass:"col-lg-3 col-md-12 col-12 px-0"},[_c('div',{staticClass:"p-2 justify-content-center text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid rounded-circle object-fit-cover",staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.getInstructorAvatar(_vm.getPublicCurrentClass)
                            ? _vm.getInstructorAvatar(_vm.getPublicCurrentClass)
                            : '@/assets/images/logo/placeholder.png',"alt":"Card image cap"}})])])]),_c('div',{staticClass:"col-lg-9 col-sm-12 pl-2 pr-2"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"d-flex text-left text-uppercase show-courses__text pl-0",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getPublicCurrentClass.title)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(
                              _vm.getPublicCurrentClass &&
                              _vm.getPublicCurrentClass.instructors.length
                            )?_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" Por "),_vm._l((_vm.getPublicCurrentClass.instructors),function(instructor,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${
                                  instructor.id
                                }/${_vm.getInstructorSlug(instructor)}`}},[_vm._v(" "+_vm._s(instructor.firstname)+" "+_vm._s(instructor.lastname)+" ")]),(
                                  index !==
                                  _vm.getPublicCurrentClass.instructors.length - 1
                                )?_c('span',[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},_vm._l((_vm.getPublicCurrentClass.categories),function(category,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/explorar/categoria/${category.slug}`}},[_vm._v(" "+_vm._s(category.title)+" ")]),(
                                  index !==
                                  _vm.getPublicCurrentClass.categories.length - 1
                                )?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0)])])])])])]),_c('div',{staticClass:"padding-videos show-videos-class-in-desktop"},[_c('div',{staticClass:"show-courses__videos"},[_c('badger-accordion',{ref:"badgerAccordion",staticClass:"accordion course-container__nav__chapter-collapse pt-4",attrs:{"icons":{
                      opened: `<i class='fas fa-angle-up'></i>`,
                      closed: `<i class='fas fa-angle-down'></i>`,
                    }}},[_c('div',{staticClass:"mb-4"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":`/clase/${_vm.getPublicCurrentClass.slug}`}},[_c('div',{staticClass:"card",staticStyle:{"margin-bottom":"0 !important","margin-top":"-10px !important","cursor":"pointer","background":"black"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-mobile-img col-5 col-md-mobile col-md-5 pr-0",staticStyle:{"background":"#000"}},[_c('div',{staticStyle:{"border-radius":"6px"}},[_c('img',{staticClass:"img-see-course",staticStyle:{"padding":"0","object-fit":"cover","max-height":"90px","width":"100%"},attrs:{"src":_vm.getPublicCurrentClass.poster
                                      ? _vm.getPublicCurrentClass.poster.url
                                      : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg',"alt":"Card image cap"}}),_c('div',{staticClass:"card-img-overlay timeLesson d-flex justify-content-between"},[_c('div',{staticClass:"details-play"},[_c('div')]),_c('a',{staticClass:"btn duration-style btn-sm text-right"},[_vm._v(_vm._s(_vm.formatTime(_vm.getPublicCurrentClass)))])]),_c('div',{staticClass:"progress progress-show-courses"},[_c('div',{staticClass:"progress-bar",style:({
                                      width: `${_vm.currentTimePercent}%`,
                                    }),attrs:{"aria-valuenow":_vm.currentTimePercent,"aria-valuemax":"100","aria-valuemin":"0","role":"progressbar"}})])])]),_c('div',{staticClass:"col-mobile-title col-7 col-md-7 show-courses__info-videos"},[_c('h5',{staticClass:"text-left show-courses__text text-mobile-descript"},[_vm._v(" "+_vm._s(_vm.getPublicCurrentClass.title)+" ")])])])])])],1)])],1)])])])])]),_c('div',{staticClass:"pt-5 show-courses__mobile-container text-description-class bg-white"},[_c('div',{staticClass:"container-fluid size"},[(_vm.isLoading)?_c('skeleton-course-video-details'):_c('class-video-details',{attrs:{"data":_vm.getPublicCurrentClass}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }