<template>
  <div>
    <div class="allClasses__container__classes skeleton-container">
      <skeleton-loading>
        <skeleton-square
          :box-properties="{
            top: '10px',
            height: '160px',
          }"
          :count="1"
        >
        </skeleton-square>
        <div class="p-3 mb-1">
          <skeleton-square
            :box-properties="{
              top: '10px',
              height: '20px',
            }"
            :count="2"
          ></skeleton-square>
          <skeleton-square
            :box-properties="{
              top: '20px',
              height: '20px',
            }"
            :count="1"
          ></skeleton-square>
        </div>
      </skeleton-loading>
    </div>
  </div>
</template>

<script>
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';

export default {
  name: 'SkeletonCardVideo',
  components: { SkeletonSquare, SkeletonLoading },
};
</script>

<style scoped>
.skeleton-container {
  height: 290px;
  background-color: white;
}
</style>
