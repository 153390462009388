var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h1',{staticClass:"allClasses__subtitles mobile-space m-0"},[_vm._v(" Todas las Clases destacadas ")]),(_vm.isLoading)?_c('div',{staticClass:"row mt-4"},_vm._l((4),function(index){return _c('skeleton-card-video',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4"})}),1):_c('div',{staticClass:"row mt-4"},[(
          _vm.getPublicFeaturedClasses.items &&
          _vm.getPublicFeaturedClasses.items.length
        )?_vm._l((_vm.getPublicFeaturedClasses.items),function(_class){return _c('card-class',{key:_class.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4",attrs:{"data":_class}})}):[_vm._m(0)]],2),(
        _vm.getPublicFeaturedClasses.items &&
        _vm.getPublicFeaturedClasses.items.length
      )?_c('div',[(_vm.totalPages > 1)?_c('div',{staticClass:"pt-4 pb-2 d-flex justify-content-end border-top-0"},[_c('base-pagination',{staticClass:"pagination-style",attrs:{"value":_vm.page,"page-count":_vm.totalPages},on:{"input":_vm.paginationChange}})],1):_vm._e()]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mx-auto"},[_c('h3',[_vm._v("No hay contenido disponible")])])
}]

export { render, staticRenderFns }