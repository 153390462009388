<template>
  <div class="row mt-5">
    <div class="col-sm-12">
      <div
        class="d-flex justify-content-between align-items-center class-featured-md"
      >
        <h1 class="allClasses__subtitles mobile-space m-0">Cursos</h1>
        <div>
            <router-link class="text-decoration-none" :to="$route.params.slug ? `/explorar/cursos/categoria/${$route.params.slug}`:'/explorar/cursos'">
            <button
              class="allClasses__container__classes__button rounded mx-lg-0"
            >
              Ver Todos
            </button>
          </router-link>
        </div>
      </div>

      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-6 col-lg-4"
        />
      </div>
      <div v-else>
        <div
          v-if="getPublicCourses.items && getPublicCourses.items.length"
          class="row show-in-desktop"
        >
          <card-course
            v-for="course in getPublicCourses.items"
            :key="course.id"
            :data="course"
            class="col-12 col-sm-6 col-md-6 col-lg-4"
          />
        </div>
        <div
          v-if="getPublicCourses.items && getPublicCourses.items.length"
          class="col-12 view-home-tabs mx-0 px-0 view-tabs-mobile mb-4 show-in-mobile"
        >
          <carousel
            :navigation-enabled="true"
            :per-page="1"
            :per-page-custom="[
              [545, 2],
              [825, 3],
              [1200, 4],
            ]"
            :scroll-per-page="true"
            :speed="500"
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for="course in getPublicCourses.items"
              :key="course.id"
              class="d-flex flex-column"
            >
              <card-course :data="course" class="card-course-width mx-0" />
            </slide>
          </carousel>
        </div>
        <div v-else class="text-center pt-6 pb-6">
          <h3>No hay contenido disponible</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonCardVideo from '../../../Components/skeletons/SkeletonCardVideo.vue';
import { FETCH_PUBLIC_COURSES } from '../../../../store/actions/public';
import { mapGetters } from 'vuex';
import CardCourse from './CardCourse.vue';

export default {
  name: 'SectionCourses',
  components: {
    CardCourse,
    SkeletonCardVideo,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getPublicCourses']),
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.isLoading = true;
      this.fetchCourses({
        category_slug: slug,
        per_page: 3,
        page: this.page,
      });
    },
  },
  mounted() {
    this.fetchCourses();
  },
  methods: {
    fetchCourses(
      filter = {
        category_slug: this.$route.params.slug,
        per_page: 3,
        page: this.page,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_COURSES, filter)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.notify('danger', 'Tenemos problemas para cargar los cursos');
        });
    },
  },
};
</script>

<style>
.md-tabs-navigation .md-button-content {
  color: #fff;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background-color: #000;
}

.show-in-mobile {
  display: none;
}

@media (max-width: 600px) {
  .show-in-desktop {
    display: none;
  }
  .show-in-mobile {
    display: block;
    padding-top: 10px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .class-featured-md {
    padding-right: 15px;
  }
}
</style>
