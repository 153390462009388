var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-h-w d-flex flex-column position-relative mt-2"},[_c('div',{staticClass:"allClasses__container__classes d-flex flex-column position-relative"},[(_vm.checkLimitCreateDate())?_c('div',{staticClass:"position-absolute tag-card-class"},[_c('span',{staticClass:"card-video-tag"},[_vm._v(" NUEVO ")])]):_vm._e(),_c('div',{staticClass:"position-absolute card-video-time-container"},[_c('span',{staticClass:"card-video-time"},[_vm._v(" "+_vm._s(_vm.formatTime( _vm.data.video && _vm.data.video.duration ? _vm.data.video.duration : 0, )))])]),_c('router-link',{attrs:{"to":_vm.redirect ? _vm.redirect : `/clase/${_vm.data.slug}`}},[_c('div',{staticClass:"text-header-img"},[_c('img',{staticClass:"image-card-classes",attrs:{"src":_vm.data.poster && _vm.data.poster.url
              ? _vm.data.poster.url
              : require('@/assets/images/logo/placeholder.png'),"alt":"video"}})])]),_c('div',{staticClass:"card-class-text d-flex flex-column justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"text-white text-uppercase font-weight-bolder card-course-title mr-1"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.isAuthenticated)?_c('div',[_c('i',{class:{
              'fas fa-heart': _vm.isFavorite,
              'far fa-heart ': !_vm.isFavorite,
            },staticStyle:{"cursor":"pointer","color":"white","font-size":"16px"},on:{"click":function($event){return _vm.addClassFavorite()}}})]):_vm._e()]),_c('div',{staticClass:"mt-1 mb-0 card-course-instructor"},[(_vm.data.instructors && _vm.data.instructors.length)?[_c('div',{staticClass:"text-white"},_vm._l((_vm.data.instructors),function(instructor,index){return _c('span',{key:instructor.id,staticClass:"d-inline-block mr-1"},[_vm._v(" "+_vm._s(index ? ',' : null)+" "),_c('router-link',{staticClass:"text-uppercase font-weight-bolder white-text text-decoration-none",attrs:{"to":`/instructores/instructor/${
                  instructor.id
                }/${instructor.firstname.toLowerCase()}-${instructor.lastname.toLowerCase()}`}},[_vm._v(" "+_vm._s(`${instructor.firstname} ${instructor.lastname}`)+" ")])],1)}),0)]:[_c('span',{staticClass:"text-uppercase font-weight-bolder white-text text-decoration-none"},[_vm._v(" No hay instructor seleccionado ")])]],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }