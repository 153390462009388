<template>
  <div>
    <featured-classes />
    <popular-classes />
    <trend-classes />
    <new-classes />
    <courses />
  </div>
</template>

<script>
import FeaturedClasses from './Class/components/FeaturedClasses.vue';
import PopularClasses from './Class/components/PopularClasses.vue';
import Courses from './Courses/components/Courses.vue';
import TrendClasses from './Class/components/TrendClasses.vue';
import { mapGetters } from 'vuex';
import NewClasses from './Class/components/NewClasses.vue';
export default {
  name: 'ExploreClass',
  metaInfo () {
    const slug = this.$route.params.slug;
    const path = this.$route.path;

    if(path === '/explorar') return {
      title: 'Explorar - Maquillate.com'
    }

    const category = this.getPublicCategories.find((publicCategory) => publicCategory.slug === slug);
    return {
      title: category ? `${category.title.charAt(0).toUpperCase()}${category.title.slice(1)} - Maquillate.com`: 'Explorar Clases - Maquillate.com',
    };
  },
  components: {
    FeaturedClasses,
    PopularClasses,
    TrendClasses,
    Courses,
    NewClasses,
  },
  computed: {
    ...mapGetters(['getPublicCategories', 'getIsLoadingCategories']),
  },
};
</script>

<style scoped></style>
