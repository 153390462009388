<template>
  <div class='row'>
    <div class='col-sm-12'>
      <h1 class='allClasses__subtitles mobile-space m-0'>
        Todas las Clases destacadas
      </h1>
      <div v-if='isLoading' class='row mt-4'>
        <skeleton-card-video
          v-for='index in 4'
          :key='index'
          class='col-12 col-sm-6 col-md-6 col-lg-4'
        />
      </div>
      <div v-else class='row mt-4'>
        <template
          v-if='
            getPublicFeaturedClasses.items &&
            getPublicFeaturedClasses.items.length
          '
        >
          <card-class
            v-for='_class in getPublicFeaturedClasses.items'
            :key='_class.id'
            :data='_class'
            class='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4'
          />
        </template>
        <template v-else>
          <div class='text-center mx-auto'>
            <h3>No hay contenido disponible</h3>
          </div>
        </template>
      </div>
      <div
        v-if='
          getPublicFeaturedClasses.items &&
          getPublicFeaturedClasses.items.length
        '
      >
        <div
          v-if='totalPages > 1'
          class='pt-4 pb-2 d-flex justify-content-end border-top-0'
        >
          <base-pagination
            :value='page'
            class='pagination-style'
            :page-count='totalPages'
            @input='paginationChange'
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FETCH_PUBLIC_FEATURED_CLASSES } from '../../../store/actions/public';
import { mapGetters } from 'vuex';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo.vue';
import BasePagination from '/src/components/BasePagination';
import CardClass from '../Courses/components/CardClass.vue';

export default {
  name: 'ClassFeaturedView',

  components: {
    SkeletonCardVideo,
    CardClass,
    BasePagination,
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      perPage: 20,
    };
  },
  computed: {
    ...mapGetters(['getPublicFeaturedClasses', 'getPublicCategories']),
    totalPages () {
      return Math.ceil(this.getPublicFeaturedClasses.total / this.perPage);
    },
  },
  metaInfo () {
    const slug = this.$route.params.slug;
    const category = this.getPublicCategories.find((publicCategory) => publicCategory.slug === slug);
    return {
      title: category ? `${category.title}`: 'Explorar Clases - Maquillate.com',
    };
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.page = 1;
      this.isLoading = true;
      await this.fetchClassFeatured({
        category_slug: slug,
        per_page: this.perPage,
        featured: 1,
        page: 1,
      });
    },
  },
  async mounted () {
    await this.fetchClassFeatured();
  },
  methods: {
    async fetchClassFeatured (
      filter = {
        category_slug: this.$route.params.slug,
        per_page: this.perPage,
        featured: 1,
      },
    ) {
      await this.$store.dispatch(FETCH_PUBLIC_FEATURED_CLASSES, filter);
      this.isLoading = false;
    },
    async paginationChange (page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_PUBLIC_FEATURED_CLASSES, {
        page,
        per_page: this.perPage,
        featured: 1,
        category_slug: this.$route.params.slug,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.md-tabs-navigation .md-button-content {
  color: #fff;
}

.view-home-tabs
.md-tabs.md-theme-default
.md-tabs-navigation
.md-button.md-active {
  background-color: #000;
}

.view-home-tabs
.md-tabs.md-theme-default
.md-tabs-navigation
.md-button:first-child:hover,
.view-home-tabs
.md-tabs.md-theme-default
.md-tabs-navigation
.md-button:first-child:active,
.view-home-tabs
.md-tabs.md-theme-default
.md-tabs-navigation
.md-button.md-active:first-child {
  background-color: #b7227e;
}

.view-tabs-mobile {
  display: none;
}

@media (max-width: 700px) {
  .view-tabs-mobile {
    display: block;
  }
}
</style>
