<template>
  <div class="card-h-w d-flex flex-column position-relative mt-2">
    <div
      class="allClasses__container__classes d-flex flex-column position-relative"
    >
      <div
        v-if="checkLimitCreateDate()"
        class="position-absolute tag-card-class"
      >
        <span class="card-video-tag"> NUEVO </span>
      </div>

      <div class="position-absolute card-video-time-container">
        <span class="card-video-time">
          {{
            formatTime(
              data.video && data.video.duration ? data.video.duration : 0,
            )
          }}</span
        >
      </div>

      <router-link :to="redirect ? redirect : `/clase/${data.slug}`">
        <div class="text-header-img">
          <img
            :src="
              data.poster && data.poster.url
                ? data.poster.url
                : require('@/assets/images/logo/placeholder.png')
            "
            alt="video"
            class="image-card-classes"
          />
        </div>
      </router-link>
      <div class="card-class-text d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between">
          <h3
            class="text-white text-uppercase font-weight-bolder card-course-title mr-1"
          >
            {{ data.title }}
          </h3>
          <div v-if="isAuthenticated">
            <i
              :class="{
                'fas fa-heart': isFavorite,
                'far fa-heart ': !isFavorite,
              }"
              style="cursor: pointer; color: white; font-size: 16px"
              @click="addClassFavorite()"
            ></i>
          </div>
        </div>
        <div class="mt-1 mb-0 card-course-instructor">
          <template v-if="data.instructors && data.instructors.length">
            <div class="text-white">
              <span
                v-for="(instructor, index) in data.instructors"
                :key="instructor.id"
                class="d-inline-block mr-1"
              >
                {{ index ? ',' : null }}
                <router-link
                  :to="`/instructores/instructor/${
                    instructor.id
                  }/${instructor.firstname.toLowerCase()}-${instructor.lastname.toLowerCase()}`"
                  class="text-uppercase font-weight-bolder white-text text-decoration-none"
                >
                  {{ `${instructor.firstname} ${instructor.lastname}` }}
                </router-link>
              </span>
            </div>
          </template>
          <template v-else>
            <span
              class="text-uppercase font-weight-bolder white-text text-decoration-none"
            >
              No hay instructor seleccionado
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import format from 'format-duration';
import { mapGetters } from 'vuex';
import { UPDATE_CLASS_USER } from '../../../../store/actions/classes';
import { USER_CLASS_FAVORITE } from '../../../../store/actions/user';
import moment from 'moment';
export default {
  name: 'CardClass',
  props: {
    data: {
      type: Object,
      required: true,
    },
    redirect: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getUserFavoriteClasses', 'getProfile']),
  },
  watch: {
    getUserFavoriteClasses: function (value) {
      if (value && value.items && value.items.length) {
        this.isFavorite = value.items.find((item) => item.id === this.data.id);
      }
    },
  },
  mounted() {
    if (
      this.getUserFavoriteClasses &&
      this.getUserFavoriteClasses.items &&
      this.getUserFavoriteClasses.items.length
    ) {
      this.isFavorite = this.getUserFavoriteClasses.items.find(
        (item) => item.id === this.data.id,
      );
    }
  },
  methods: {
    checkLimitCreateDate() {
      if (!(this.data && this.data.created_at)) return false;
      const limitDate = moment().diff(moment(this.data.created_at), 'days');
      return limitDate < 14;
    },
    formatTime(time) {
      return format(time * 1000);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    addClassFavorite() {
      this.$store
        .dispatch(UPDATE_CLASS_USER, {
          class_user_id: this.getProfile.id,
          class_id: this.data.id,
          is_favorite: !this.isFavorite,
        })
        .then(() => {
          this.notify(
            'success',
            `${
              this.isFavorite
                ? 'La clase se elimino de tus favoritos'
                : 'La clase se agrego a tus favoritos'
            }`,
          );
          this.isFavorite = !this.isFavorite;
          this.fetchClasses();
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para agregar a favoritos'),
        );
    },
    fetchClasses() {
      this.$store
        .dispatch(USER_CLASS_FAVORITE, {
          is_favorite: 1,
          page: this.page,
          per_page: 100,
        })
        .then(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.card-video-time-container {
  z-index: 1;
  background-color: #b7227e;
  border-radius: 5px;
  line-height: 0px !important;
  padding: 10px;
  right: 15px;
  top: 17px;
}

.card-video-time-container span {
  line-height: 5px !important;
}

.card-video-time {
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.card-course-title {
  font-weight: 900 !important;
  font-size: 14px;
  text-align: start;
}

.card-course-instructor {
  text-align: start;
}

.white-text {
  font-size: 12px;
  color: #fff !important;
}

.card-class-text {
  height: 140px;
  padding: 20px 18px;
}

.image-card-classes {
  height: 160px;
  width: 100%;
  display: cover;
  border-radius: 10px 10px 0px 0px;
  border: none;
  object-fit: cover;
}

.tag-class-span {
  color: #fff;
}

.tag-card-class {
  z-index: 1;
  background-color: #b7227e;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
  left: 15px;
  top: 17px;
  height: 25px;
  align-content: center;
  line-height: 2px !important;
}

.card-video-tag {
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.allClasses__container__classes {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .card-h-w {
    height: 300px;
    border-radius: 10px;
    width: 100%;
 
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .card-h-w {
    height: 300px;
    border-radius: 10px;
    width: 100%;
    padding-right: 10px;
  }
}
</style>
