<template>
  <div class='highlight-body showClasses-rut'>
    <div class='page-container md-layout-column'>
      <div>
        <div
          class='container-video-class h-100 show-courses__body show-classes_video'
        >
          <div class='container-fluid size'>
            <div class='row justify-content-between'>
              <div class='col-lg-8 col-md-12 col-12 p-0'>
                <class-video
                  style='background-size: cover; !important'
                  @current-time-video='currentTimeVideo'
                  @end-video='endVideo'
                />
              </div>
              <div v-if='isLoading' class='col-lg-4 col-md-12 col-12 d-flex'>
                <loading-panel
                  style='
                    width: 100%;
                    background: transparent !important;
                    height: 100% !important;
                  '
                />
              </div>
              <div
                v-else
                class='show-videos-class-in-desktop col-lg-4 col-md-12 col-12 col-refactoring-boxs show-courses__info-instructor px-0 card-instructor-info'
              >
                <div class='row show-courses__info-descrip-instructor mx-1'>
                  <div class='col-lg-3 col-md-12 col-12 px-0'>
                    <div class='p-2 justify-content-center text-center'>
                      <div
                        class='d-flex align-items-center justify-content-center'
                      >
                        <img
                          :src="
                            getInstructorAvatar(getPublicCurrentClass)
                              ? getInstructorAvatar(getPublicCurrentClass)
                              : '@/assets/images/logo/placeholder.png'
                          "
                          alt='Card image cap'
                          class='img-fluid rounded-circle object-fit-cover'
                          style='width: 70px; height: 70px; object-fit: cover'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='col-lg-9 col-sm-12 pl-2 pr-2'>
                    <div class='card-body'>
                      <h5
                        class='d-flex text-left text-uppercase show-courses__text pl-0'
                        style='font-weight: 700'
                      >
                        {{ getPublicCurrentClass.title }}
                      </h5>

                      <div class='d-flex flex-row' style='max-width: 300px'>
                        <div class='row'>
                          <div class='col-12'>
                            <h5
                              v-if='
                                getPublicCurrentClass &&
                                getPublicCurrentClass.instructors.length
                              '
                              class='text-uppercase show-courses__text text-left'
                              style='font-size: 12px'
                            >
                              Por
                              <span
                                v-for='(
                                  instructor, index
                                ) in getPublicCurrentClass.instructors'
                                :key='index'
                              >
                                <router-link
                                  :to='`/instructores/instructor/${
                                    instructor.id
                                  }/${getInstructorSlug(instructor)}`'
                                  style='text-decoration: none'
                                >
                                  {{ instructor.firstname }}
                                  {{ instructor.lastname }}
                                </router-link>
                                <span
                                  v-if='
                                    index !==
                                    getPublicCurrentClass.instructors.length - 1
                                  '
                                >,
                                </span>
                              </span>
                            </h5>
                          </div>

                          <div class='col-12'>
                            <h5
                              class='text-uppercase show-courses__text text-left'
                              style='font-size: 12px'
                            >
                              <span
                                v-for='(
                                  category, index
                                ) in getPublicCurrentClass.categories'
                                :key='index'
                              >
                                <router-link
                                  :to='`/explorar/categoria/${category.slug}`'
                                  style='text-decoration: none'
                                >
                                  {{ category.title }}
                                </router-link>
                                <span
                                  v-if='
                                    index !==
                                    getPublicCurrentClass.categories.length - 1
                                  '
                                >,
                                </span>
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='padding-videos show-videos-class-in-desktop'>
                  <div class='show-courses__videos'>
                    <badger-accordion
                      ref='badgerAccordion'
                      :icons="{
                        opened: `<i class='fas fa-angle-up'></i>`,
                        closed: `<i class='fas fa-angle-down'></i>`,
                      }"
                      class='accordion course-container__nav__chapter-collapse pt-4'
                    >
                      <div class='mb-4'>
                        <router-link
                          :to='`/clase/${getPublicCurrentClass.slug}`'
                          class='text-decoration-none'
                        >
                          <div
                            class='card'
                            style='
                              margin-bottom: 0 !important;
                              margin-top: -10px !important;
                              cursor: pointer;
                              background: black;
                            '
                          >
                            <div class='row'>
                              <div
                                class='col-mobile-img col-5 col-md-mobile col-md-5 pr-0'
                                style='background: #000'
                              >
                                <div style='border-radius: 6px'>
                                  <img
                                    :src="
                                      getPublicCurrentClass.poster
                                        ? getPublicCurrentClass.poster.url
                                        : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg'
                                    "
                                    alt='Card image cap'
                                    class='img-see-course'
                                    style='
                                      padding: 0;
                                      object-fit: cover;
                                      max-height: 90px;
                                      width: 100%;
                                    '
                                  />
                                  <div
                                    class='card-img-overlay timeLesson d-flex justify-content-between'
                                  >
                                    <div class='details-play'>
                                      <div></div>
                                    </div>
                                    <a
                                      class='btn duration-style btn-sm text-right'
                                    >{{
                                        formatTime(getPublicCurrentClass)
                                      }}</a
                                    >
                                  </div>
                                  <div class='progress progress-show-courses'>
                                    <div
                                      :aria-valuenow='currentTimePercent'
                                      :style='{
                                        width: `${currentTimePercent}%`,
                                      }'
                                      aria-valuemax='100'
                                      aria-valuemin='0'
                                      class='progress-bar'
                                      role='progressbar'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class='col-mobile-title col-7 col-md-7 show-courses__info-videos'
                              >
                                <h5
                                  class='text-left show-courses__text text-mobile-descript'
                                >
                                  {{ getPublicCurrentClass.title }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </badger-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='pt-5 show-courses__mobile-container text-description-class bg-white'>
          <div class='container-fluid size'>
            <skeleton-course-video-details v-if='isLoading' />

            <class-video-details v-else :data='getPublicCurrentClass' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'format-duration';
import config from '@/config/config';
import ClassVideoDetails from './components/ClassVideoDetails.vue';
import LoadingPanel from '../../../components/LoadingPanel.vue';
import { mapGetters } from 'vuex';
import SkeletonCourseVideoDetails from '../../Components/skeletons/SkeletonCourseVideoDetails.vue';
import ClassVideo from './components/ClassVideo.vue';
import 'video.js/dist/video-js.css';
import { FETCH_CURRENT_CLASS } from '../../../store/actions/public';
import { BadgerAccordion } from 'vue-badger-accordion';
import SocketIO from '../../../socket/SocketIO';
import {
  FETCH_CURRENT_CLASS_USER,
  UPDATE_CLASS_USER,
} from '../../../store/actions/classes';

export default {
  name: 'ClassView',
  components: {
    SkeletonCourseVideoDetails,
    LoadingPanel,
    ClassVideoDetails,
    ClassVideo,
    BadgerAccordion,
  },
  data () {
    return {
      isLoading: true,
      isError: null,
      currentTime: 0,
      currentTimePercent: 0,
    };
  },
  metaInfo () {
    const seoTitle = this.getPublicCurrentClass && this.getPublicCurrentClass.seo ? `${this.getPublicCurrentClass.seo.title || ''}` : undefined;
    const seoDescription = this.getPublicCurrentClass && this.getPublicCurrentClass.seo ? `${this.getPublicCurrentClass.seo.description || ''}` : undefined;

    return {
      title: seoTitle ?? 'Maquillate.com',
      meta: [{
        name: 'description',
        content: seoDescription ?? 'Maquillate.com',
      }],
    };
  },
  computed: {
    ...mapGetters([
      'getPublicCurrentClass',
      'isAuthenticated',
      'getProfile',
      'getCurrentClassUser',
    ]),
  },
  watch: {

    getCurrentClassUser: function (newValue) {
      console.log('getCurrentClassUser', newValue);
      this.currentTimePercent = this.getClassCurrentTimePercent(
        newValue.currentTime,
        this.getPublicCurrentClass.video,
      );
    },
  },
  async mounted () {
    await this.fetchCurrentClass();
    if (this.getProfile && this.getProfile.id) {
      SocketIO.connect(this.getProfile);
    }
  },
  beforeUnmount () {
    SocketIO.disconnect();
  },
  methods: {
    currentTimeVideo (currentTime) {
      const classId = this.getPublicCurrentClass.id;
      if (currentTime > this.currentTime) {
        this.currentTime += 1;
        this.currentTimePercent = (
          (currentTime * 100) /
          this.getPublicCurrentClass.video.duration
        ).toFixed(2);
        SocketIO.emit(`class_reproduce_time`, {
          currentTime,
          classId,
        });
      }
    },
    fetchCurrentClassUser () {
      this.$store.dispatch(FETCH_CURRENT_CLASS_USER, {
        user_id: this.getProfile.id,
        class_id: this.getPublicCurrentClass.id,
      });
    },
    async fetchCurrentClass () {
      await this.$store.dispatch(FETCH_CURRENT_CLASS, this.$route.params.slug);
      this.isLoading = false;
    },
    formatTime (courseVideo) {
      const duration = courseVideo.video ? courseVideo.video.duration : 0;

      return format(duration * 1000);
    },
    getCourseVideoThumbnail (courseVideo) {
      if (!courseVideo.video)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return courseVideo.video.thumbnail;
    },
    getInstructorImage (instructors = []) {
      if (!instructors.length)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return instructors[0].thumbnail_profile;
    },
    getCourseDuration (courseVideos = []) {
      const duration = courseVideos.reduce((acc, curr) => {
        const _duration = curr.video ? parseInt(curr.video.duration) : 0;
        return acc + _duration;
      }, 0);

      return format(duration * 1000);
    },
    getInstructors (instructors) {
      if (!instructors.length) return 'Sin instructores asignados';

      return instructors
        .map((instructor) => `${instructor.firstname} ${instructor.lastname}`)
        .join(' , ');
    },
    getClassContent (_class) {
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = _class.content;
      return htmlObject.textContent;
    },
    getInstructorAvatar (_class) {
      if (_class && _class.instructors && _class.instructors.length) {
        return _class.instructors[0] && _class.instructors[0].avatar
          ? _class.instructors[0].avatar.url
          : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
      }
      return 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
    },

    getClassCurrentTimePercent (currentTime, video) {
      if (!currentTime) return 0;

      if (currentTime > video.duration) return 0;

      return ((currentTime * 100) / video.duration).toFixed(2);
    },

    endVideo (currentTime) {
      this.currentTime = 1;
      this.$store.dispatch(UPDATE_CLASS_USER, {
        class_user_id: this.getProfile.id,
        class_id: this.getPublicCurrentClass.id,
        is_completed: 1,
        class_current_time: currentTime,
      });
    },
    getInstructorSlug (instructor) {
      const fullname = `${instructor.firstname ?? ''} ${
        instructor.lastname ?? ''
      }`;
      return fullname.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
a:not(.md-button):hover {
  text-decoration: none;
}

.card-body {
  padding: 0.5rem;
}

.view-home__part5.show-courses__body {
  padding: 80px 0px 0px !important;
}

.show-courses__info-descrip-instructor {
  border-radius: 8px !important;
}

.md-theme-default a:not(.md-button) {
  color: #fff;
}

.show-courses__videos {
  overflow: auto !important;
  height: 320px;
}

.vjs-poster {
  background-size: 100px !important;
}

.container-video-class {
  text-align: center;
  color: #000;
  background-color: black;
  padding: 80px 0px 20px 0px !important;
}

@media screen and (max-width: 992px) {
  .show-courses__img-instructor img {
    width: 90px !important;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  .show-classes_video {
    padding: 60px 0px 0px 0px !important;
  }

  .show-videos-class-in-desktop {
    display: none;
  }

  .bg-white {
    background-color: white !important;
  }
}
</style>
