<template>
  <div class='row'>
    <div
      class='col-8 mb-2 text-left desk-title'
    >
      <skeleton-square
        :box-properties="{
              top: '10px',
              height: '26px',
              width: '100%',
              bottom: '20px'
            }"
        :count="1"
      ></skeleton-square>
    </div>

    <div
      class='col-4 d-flex justify-content-end pt-2'
    >
      <div class='mr-3'>
        <skeleton-circle
          :box-properties="{
                height: '30px',
                width:'30px'
                }"
          :count='1'
        />
      </div>
      <div>
        <skeleton-circle
          :box-properties="{
                height: '30px',
                width:'30px'
                }"
          :count='1'
        />
      </div>
    </div>
    <div
      class='col-12'
    >
      <h2 style='color: #b7227e'>
        <skeleton-square
          :box-properties="{
              top: '10px',
              height: '22px',
              width: '100%',
              bottom: '20px'
            }"
          :count="4"
        ></skeleton-square>
      </h2>
    </div>
  </div>
</template>

<script>
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonCircle from '../../../components/Skeleton/components/SkeletonCircle';
export default {
  name: 'SkeletonCourseVideoDetails',
  components:{
    SkeletonSquare,
    SkeletonCircle
  }
};
</script>

<style scoped>

</style>
