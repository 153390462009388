<template>
  <div class="row">
    <div class="col-sm-12">
      <h1 class="allClasses__subtitles pt-2 mobile-space m-0">
        Todas las Clases populares
      </h1>
      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video v-for="index in 4" :key="index" class="col-sm-3" />
      </div>
      <div v-else class="row mt-4">
        <card-class
          v-for="_class in getPopularClasses.items"
          :key="_class.id"
          :data="_class"
          class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2 pb-2"
        />
      </div>
      <div v-if="!getPopularClasses.items.length" class="text-center">
        <h3>No hay contenido disponible</h3>
      </div>
      <div v-if="getPopularClasses.items && getPopularClasses.items.length">
        <div
          v-if="totalPages > 1"
          class="pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FETCH_POPULAR_CLASSES } from '../../../store/actions/public';
import { mapGetters } from 'vuex';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo.vue';
import CardClass from '../Courses/components/CardClass.vue';

export default {
  name: 'ClassPopularView',
  components: {
    SkeletonCardVideo,
    CardClass,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      perPage: 12,
    };
  },
  metaInfo () {
    const slug = this.$route.params.slug;
    const category = this.getPublicCategories.find((publicCategory) => publicCategory.slug === slug);
    return {
      title: category ? `${category.name}`: 'Explorar Clases - Maquillate.com',
    };
  },
  computed: {
    ...mapGetters(['getPopularClasses', 'getPublicCategories']),
    totalPages() {
      return Math.ceil(this.getPopularClasses.total / this.perPage);
    },
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.isLoading = true;
      this.page = 1;
      await this.fetchPopularClassVideos({
        category_slug: slug,
        per_page: this.perPage,
        page: 1,
      });
    },
  },
  async mounted() {
    await this.fetchPopularClassVideos();
  },
  methods: {
    async fetchPopularClassVideos(
      filter = {
        category_slug: this.$route.params.slug,
        per_page: this.perPage,
        page: this.page,
      },
    ) {
      await this.$store.dispatch(FETCH_POPULAR_CLASSES, filter);
      this.isLoading = false;
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;

      await this.fetchPopularClassVideos({
        category_slug: this.$route.params.slug,
        per_page: this.perPage,
        page,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.md-tabs-navigation .md-button-content {
  color: #fff;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background-color: #000;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:hover,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:active,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active:first-child {
  background-color: #b7227e;
}
</style>
