<template>
  <div class="row mt-5">
    <div class="col-sm-12">
      <div
        class="d-flex justify-content-between align-items-center class-featured-md"
      >
        <h1 class="allClasses__subtitles mobile-space m-0 p-0">
          Nuevas Clases
        </h1>
        <div>
          <router-link class="text-decoration-none" :to="$route.params.slug ? `/explorar/clases/categoria/${$route.params.slug}`:'/explorar/clases'">
            <button
              class="allClasses__container__classes__button rounded mx-0 mr-lg-0 p-r-view-classes-mobile"
            >
              Ver Todos
            </button>
          </router-link>
        </div>
      </div>

      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-6 col-lg-4"
        />
      </div>
      <div v-else>
        <div
          v-if="getPublicClasses.items && getPublicClasses.items.length"
          class="row show-in-desktop"
        >
          <card-class
            v-for="_class in getPublicClasses.items"
            :key="_class.id"
            :data="_class"
            class="col-12 col-sm-6 col-md-6 col-lg-4"
          />
        </div>

        <div
          v-if="getPublicClasses.items && getPublicClasses.items.length"
          class="col-12 view-home-tabs pl-0 mx-0 px-0 view-tabs-mobile mb-4 show-in-mobile"
        >
          <carousel
            :navigation-enabled="true"
            :per-page="1"
            :per-page-custom="[
              [545, 2],
              [825, 3],
              [1200, 4],
            ]"
            :scroll-per-page="true"
            :speed="500"
            navigation-next-label="<i class='fas fa-chevron-right'></i>"
            navigation-prev-label="<i class='fas fa-chevron-left'></i>"
          >
            <slide
              v-for="_class in getPublicClasses.items"
              :key="_class.id"
              class="d-flex flex-column"
            >
              <card-class class="card-course-width mx-0" :data="_class" />
            </slide>
          </carousel>
        </div>
        <div v-else class="text-center pt-6 pb-6">
          <h3>No hay contenido disponible</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonCardVideo from '../../../Components/skeletons/SkeletonCardVideo.vue';
import { FETCH_PUBLIC_CLASSES } from '../../../../store/actions/public';
import { mapGetters } from 'vuex';
import CardClass from '../../Courses/components/CardClass.vue';
export default {
  name: 'PopularClasses',
  components: {
    SkeletonCardVideo,
    CardClass,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getPublicClasses']),
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.isLoading = true;
      await this.fetchClassVideos({
        per_page: 3,
        order_by: 'DESC',
        category_slug: slug,
      });
    },
  },
  async mounted() {
    await this.fetchClassVideos();
  },
  methods: {
    async fetchClassVideos(
      filter = {
        per_page: 3,
        order_by: 'DESC',
        category_slug: this.$route.params.slug,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_CLASSES, filter)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.notify('danger', 'Tenemos problemas para cargar los clases');
        });
    },
  },
};
</script>

<style scoped>
.show-in-mobile {
  display: none;
}

@media (max-width: 600px) {
  .show-in-desktop {
    display: none;
  }
  .show-in-mobile {
    display: block;
    padding-top: 10px;
  }

  .p-r-view-classes-mobile {
    margin-right: 0px !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .class-featured-md {
    padding-right: 15px;
  }
}
</style>
