var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"allClasses__container__classes skeleton-container"},[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
          top: '10px',
          height: '160px',
        },"count":1}}),_c('div',{staticClass:"p-3 mb-1"},[_c('skeleton-square',{attrs:{"box-properties":{
            top: '10px',
            height: '20px',
          },"count":2}}),_c('skeleton-square',{attrs:{"box-properties":{
            top: '20px',
            height: '20px',
          },"count":1}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }