import io from 'socket.io-client';

class SocketIO {
  socket;

  constructor() {
    this.socket = io(process.env.VUE_APP_SOCKET_URL || '', {
      withCredentials: true,
      autoConnect: false,
    });
    console.log('VUE_APP_SOCKET_URL', process.env.VUE_APP_SOCKET_URL);
  }

  connect(user) {
    this.socket.auth = { user };
    this.socket.connect();
    this.socket.on('session', ({ sessionId, userId }) => {
      this.socket.auth = { sessionId };
      this.socket.userId = userId;
      localStorage.setItem('_ws_sid', sessionId);
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  emit(channel, message) {
    this.socket.emit(channel, message);
  }
}

export default new SocketIO();
