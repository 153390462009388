var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-0"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center class-featured-md"},[_c('h1',{staticClass:"allClasses__subtitles mobile-space m-0 p-0"},[_vm._v(" Clases destacadas ")]),_c('div',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.$route.params.slug ? `/explorar/destacados/categoria/${_vm.$route.params.slug}`: '/explorar/destacados'}},[_c('button',{staticClass:"allClasses__container__classes__button rounded p-r-view-classes-mobile"},[_vm._v(" Ver Todos ")])])],1)]),(_vm.isLoading)?_c('div',{staticClass:"row mt-4"},_vm._l((3),function(index){return _c('skeleton-card-video',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4"})}),1):_c('div',[(
          _vm.getPublicFeaturedClasses.items &&
          _vm.getPublicFeaturedClasses.items.length
        )?_c('div',{staticClass:"row show-in-desktop"},_vm._l((_vm.getPublicFeaturedClasses.items),function(_class){return _c('card-class',{key:_class.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4",attrs:{"data":_class}})}),1):_vm._e(),(
          _vm.getPublicFeaturedClasses.items &&
          _vm.getPublicFeaturedClasses.items.length
        )?_c('div',{staticClass:"col-12 view-home-tabs mx-0 px-0 view-tabs-mobile mb-4 show-in-mobile"},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
            [545, 2],
            [825, 3],
            [1200, 4],
          ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getPublicFeaturedClasses.items),function(_class){return _c('slide',{key:_class.id,staticClass:"d-flex flex-column"},[_c('card-class',{staticClass:"card-course-width mx-0",attrs:{"data":_class}})],1)}),1)],1):_c('div',{staticClass:"text-center pt-6 pb-6"},[_c('h3',[_vm._v("No hay contenido disponible")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }