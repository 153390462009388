<template>
  <div class='row'>
    <div class='col-lg-4 col-sm-12 mb-2 text-center text-lg-right share-mobile-display pt-0 pb-2'>
      <h2 class='show-courses__share-fav'>
        <template v-if='getCurrentClassUser && getCurrentClassUser.id'>
          <i
            :class="{
            'fas fa-heart': isFavorite,
            'far fa-heart ': !isFavorite,
          }"
            class='pr-3'
            style='cursor: pointer'
            @click='addClassFavorite()'></i>
        </template>

        <span class='sharebutton' @click='openShareClassVideoDialog()'>
          <i class='fas fa-share'></i>
          <span class='share-hidde-mobile'>COMPARTIR</span>
        </span>
      </h2>
    </div>
    <div class='col-lg-8 col-sm-12 mb-2 text-left px-3'>
      <h2 style='color: #b7227e' class='text-uppercase'>
        {{ data.title }}
      </h2>
    </div>
    <div class='col-lg-4 col-sm-12 mb-2 text-center text-lg-right share-hidde-mobile'>
      <h2 class='show-courses__share-fav'>
        <template v-if='getCurrentClassUser && getCurrentClassUser.id'>
          <i
            :class="{
            'fas fa-heart': isFavorite,
            'far fa-heart ': !isFavorite,
          }"
            class='pr-3'
            style='cursor: pointer'
            @click='addClassFavorite()'></i>
        </template>

        <span class='sharebutton' @click='openShareClassVideoDialog()'>
          <i class='fas fa-share'></i>
          <span class='share-hidde-mobile'> COMPARTIR</span>
        </span>
      </h2>
    </div>

    <div class='col-12'>
      <p class='px-0 pt-2' v-html='data.content'></p>
    </div>

    <div class='col-12 row container-buttons-see-course'>
      <a v-for='file in data.files' :key='file.id' :href='file.url' style='text-decoration: none' target='_blank'>
        <button class='btn button-view-all-instructors mt-4 mr-3 d-flex show-courses__btn-download'>
          <i class='far fa-arrow-alt-circle-down'></i>
          DESCARGAR PDF
        </button>
      </a>
    </div>
    <div class='col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center'>
      <button
        v-if='(data.products || []).length'
        class='btn show-courses__btn-products text-white ml-lg-3 mt-2 mb-3 font-button-product'
        @click='showProducts = !showProducts'>
        <i class='fas fa-shopping-cart'></i> PRODUCTOS DESTACADOS
      </button>
    </div>
    <div v-if='showProducts' class='col-12 mt-4 cards-instructors mb-2'>
      <carousel
        :navigation-enabled='true'
        :per-page='1'
        :per-page-custom='[
        [545, 2],
        [825, 3],
        [1200, 4],
      ]'
        :scroll-per-page='true'
        :speed='500'
        navigation-next-label="<i class='fas fa-chevron-right'></i>"
        navigation-prev-label="<i class='fas fa-chevron-left'></i>">
        <slide
          v-for='product in data.products'
          :key='product.id'
          class='d-flex flex-column flex-card-display'
          style='flex-basis: inherit'>
          <card-product
            class='card-class-width mx-1 card-h-w'
            :data='product'
          />
        </slide>
      </carousel>
    </div>
    <share-class-video-dialog
      :course='getPublicCurrentClass'
      :is-open='isOpenShareClassVideoDialog'
      @close-modal='closeShareClassVideoDialog' />
    <div class='show-courses__info-instructor card-instructor-info col-12 display-in-mobile-only pt-4'>
      <div class='row show-courses__info-descrip-instructor mx-1'>
        <div class='col-4 px-0 text-center'>
          <div class='p-2 justify-content-center text-center'>
            <div class='d-flex align-items-center justify-content-center'>
              <img
                :src='getInstructorAvatar(getPublicCurrentClass)'
                alt='Card image cap'
                class='img-fluid rounded-circle object-fit-cover'
                style='width: 90px; height: 90px; object-fit: cover' />
            </div>
          </div>
        </div>
        <div class='col-8 px-0'>
          <div class='card-body p-1'>
            <h4 class='d-flex text-left text-uppercase show-courses__text pl-0' style='font-weight: 700'>
              {{ getPublicCurrentClass.title }}
            </h4>

            <div class='d-flex flex-row' style='max-width: 300px'>
              <div class='row'>
                <div class='col-12'>
                  <h5 class='text-uppercase show-courses__text text-left' style='font-size: 12px'>
                    {{
                      getPublicCurrentClass.instructors
                        .map(
                          (instructor) =>
                            `${instructor.firstname} ${instructor.lastname}`,
                        )
                        .join(', ')
                    }}
                  </h5>
                </div>

                <div class='col-12'>
                  <h5 class='text-uppercase show-courses__text text-left' style='font-size: 12px'>
                    {{
                      getPublicCurrentClass.categories
                        .map((category) => category.title)
                        .join(', ')
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';
import { UPDATE_CLASS_USER } from '../../../../store/actions/classes';
import ShareClassVideoDialog from './ShareClassVideoDialog';
import format from 'format-duration';
import SocketIO from '../../../../socket/SocketIO';
import CardProduct from './CardProduct.vue';

export default {
  name: 'ClassVideoDetails',
  components: {
    Carousel,
    Slide,
    ShareClassVideoDialog,
    CardProduct,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
          files: [],
          products: [],
          instructors: [],
          categories: [],
          currentTimePercent: 0,
        };
      },
    },
    course: {
      type: Object,
      default: () => {
        return {
          thumbnail_url: '',
          id: '',
          name: '',
          slug_name: '',
        };
      },
    },
  },
  emits: ['update-class-user'],
  data () {
    return {
      showProducts: false,
      isError: null,
      isFavorite: 0,
      isOpenShareClassVideoDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentClassUser',
      'getProfile',
      'getPublicCurrentClass',
    ]),
  },
  watch: {
    getCurrentClassUser: function (newValue) {
      if (newValue) this.isFavorite = newValue.isFavorite;
    },
  },
  methods: {
    currentTimeVideo (currentTime) {
      const classId = this.getPublicCurrentClass.id;
      console.log(currentTime > this.currentTime);
      console.log('currentTime', currentTime);
      console.log('this.currentTime', this.currentTime);
      if (currentTime > this.currentTime) {
        this.currentTime += 1;
        this.currentTimePercent = this.getClassCurrentTimePercent(
          currentTime,
          this.getPublicCurrentClass.video,
        );
        console.log({
          currentTime,
          classId,
        });
        SocketIO.emit(`class_reproduce_time`, {
          currentTime,
          classId,
        });
      }
    },
    getClassCurrentTimePercent (currentTime, video) {
      if (!currentTime) return 0;

      if (currentTime > video.duration) return 0;

      return ((currentTime * 100) / video.duration).toFixed(2);
    },
    notify (type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    getInstructorAvatar (_class) {
      if (_class && _class.instructors && _class.instructors.length) {
        return _class.instructors[0] && _class.instructors[0].avatar
          ? _class.instructors[0].avatar.url
          : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
      }
      return 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
    },
    formatTime (courseVideo) {
      const duration = courseVideo.video ? courseVideo.video.duration : 0;

      return format(duration * 1000);
    },
    addClassFavorite () {
      this.$store
        .dispatch(UPDATE_CLASS_USER, {
          class_user_id: this.getProfile.id,
          class_id: this.getPublicCurrentClass.id,
          is_favorite: !this.getCurrentClassUser.isFavorite,
        })
        .then(() => {
          this.notify(
            'success',
            `${this.isFavorite
              ? 'La clase se elimino de tus favoritos'
              : 'La clase se agrego a tus favoritos'
            }`,
            (this.isFavorite = !this.isFavorite),
          );
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para agregar a favoritos'),
        );
    },
    openShareClassVideoDialog () {
      this.isOpenShareClassVideoDialog = true;
    },
    closeShareClassVideoDialog () {
      this.isOpenShareClassVideoDialog = false;
    },
  },
};
</script>

<style scoped>


.sharebutton,
.sharebutton i {
  font-size: 14px;
}

.show-courses__share-fav i {
  font-size: 17px;
}

.sharebutton:hover {
  color: #b7227e;
  cursor: pointer;
}

.sharebutton:active {
  color: #b7227e;
}

.share-mobile-display {
  display: none;
}

.text-description-data .text-description p {
  padding: 0px !important;
  margin: 0px !important;
}

.view-home__part5 p {
  padding: 0px 0px !important;
  letter-spacing: 0.6px;
  font-weight: 500;
}


.highlight-body h2::selection,
.highlight-body h3::selection,
.highlight-body span::selection,
.highlight-body label::selection,
.highlight-body small::selection,
.highlight-body img::selection,
.highlight-body a::selection {
  background-color: #b9207e !important;
}

.show-videos-class-in-mobile {
  display: none;
}

.show-courses__info-descrip-instructor {
  border-radius: 8px;
}

.display-in-mobile-only {
  display: none;
}

@media screen and (max-width: 992px) {

  .size-img-product {
    height: 300px !important;
    width: 100%;
    border: none !important;
    border-radius: 10px 10px 0px 0px !important;
    margin-top: 0px !important;
  }

  .card-h-w-product {
    width: 100%;
    padding-right: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .share-mobile-display {
    display: block;
  }

  .share-hidde-mobile {
    display: none;
  }

  .show-videos-class-in-mobile {
    display: block;
  }

  .display-in-mobile-only {
    display: block;
  }
}

a:hover {
  text-decoration: none !important;
}

.img-shadow-none {
  box-shadow: none !important;
}

.font-button-product,
.fas.fa-shopping-cart {
  font-size: 12px !important;
}


</style>
