var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 mb-2 text-left desk-title"},[_c('skeleton-square',{attrs:{"box-properties":{
            top: '10px',
            height: '26px',
            width: '100%',
            bottom: '20px'
          },"count":1}})],1),_c('div',{staticClass:"col-4 d-flex justify-content-end pt-2"},[_c('div',{staticClass:"mr-3"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '30px',
              width:'30px'
              },"count":1}})],1),_c('div',[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '30px',
              width:'30px'
              },"count":1}})],1)]),_c('div',{staticClass:"col-12"},[_c('h2',{staticStyle:{"color":"#b7227e"}},[_c('skeleton-square',{attrs:{"box-properties":{
            top: '10px',
            height: '22px',
            width: '100%',
            bottom: '20px'
          },"count":4}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }